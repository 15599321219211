.jss1 {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #536DFE;
    align-items: center;
    justify-content: center;
}
.jss3 {
    width: 165px;
    margin-bottom: 32px;
}
.jss4 {
    color: white;
    font-size: 84px;
    font-weight: 500;
}